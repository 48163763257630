import React from 'react'
import Mountains from '../../images/common/mountain-reflect.svg'
import DoubleScales from '../../images/common/ccg-scales-double.svg'

export default function HeroSection() {
  return (
    <section className="relative h-full w-full xs:flex xs:flex-col">
      <div className="relative h-full w-full p-4 mt-36 md:mt-0">
        <DoubleScales />
        <div className="absolute inset-0 flex flex-col mt-24 justify-center items-center text-copy-primary">
          <h1 className="text-center font-bold tracking-normal leading-normal flex flex-col items-center text-xl md:text-2xl lg:text-4xl">
            <span className="w-auto">Chameleon Consulting Group</span>
            <span className="w-auto">and Clarity Combine Capabilities:</span>
          </h1>
          <h3 className="text-center font-normal flex flex-col p-4 sm:pt-12 items-center justify-center">
            <span className="inline-block sm:block">
              Now providing next generation systems and cyberspace products and
              services
            </span>
            <span className="inline-block sm:block">
              to the Defense Department and Intelligence Community
            </span>
          </h3>
          <a
            href="//www.linkedin.com/pulse/copy-chameleon-consulting-group-clarity-combine-capabilities-5c6te/?trackingId=bPZN%2B6B%2FDWFBINkrAF%2FlQw%3D%3D"
            className="bg-ci-blue-400 z-10 flex flex-row items-center font-medium px-8 py-3 mt-8 cursor-pointer transition rounded-full hover:bg-ci-blue-600 duration-300 ease-out"
            rel="noopenner noreferrer"
            target="_blank"
          >
            LEARN MORE
          </a>
        </div>
      </div>
      <div className="w-full h-full p-4 mt-32 sm:mt-2">
        <Mountains />
      </div>
    </section>
  )
}
